<template>
  <div v-if="text" class="text-right">
    <small> {{ Math.ceil((text.length + 21) / 160) }} / {{ textCount }} </small>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },

  computed: {
    textCount() {
      let length =
        160 -
        (this.text.length +
          21 -
          Math.trunc((this.text.length + 21) / 160) * 160)

      return length === 160 ? 0 : length
    },
  },
}
</script>
